import * as Types from '../../../../__generated__/types.d'

import { gql } from '@apollo/client'
export type LoggedInUserFragment = {
  __typename: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  email: any
  status: Types.UserStatusEnum
  isMfaEnabled?: boolean | null
  language?: Types.LanguageCodeEnum | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  defaultCompanyId?: string | null
  companies: Array<{ __typename: 'Company'; id: string }>
  roles: Array<{
    __typename: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: Types.AuthRoleCodeEnum
  }>
}

export const LoggedInUserFragmentDoc = gql`
  fragment LoggedInUser on User {
    id
    firstName
    lastName
    email
    status
    isMfaEnabled
    language
    phoneNumber
    passwordLastChangeAt
    companies {
      id
    }
    roles {
      userId
      roleId
      name
      uiCode
      companyId
      code
    }
    defaultCompanyId
  }
`
export const namedOperations = {
  Fragment: {
    LoggedInUser: 'LoggedInUser',
  },
}
